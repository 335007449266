/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@angular/material/theming';
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
@import "./assets/scss/variables.scss";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
    $font-family: 'Roboto Mono'
);

@include angular-material-typography($custom-typography);

html,
body {
    margin: 0;
    background-color: #000000ad;
}





h1,
h2,
h3,
h4,
h5,
p,
div {
    font-family: 'Roboto Mono', monospace;
}

a {
    color: $color-main;
    text-decoration: none;
}

.mat-toolbar {
    background: $color-white;
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, .1);
    color: $color-grey;
    position: fixed;
    top: 0;
    z-index: 1;

    a {
        @extend %transition;
        color: $color-grey;
        transition-property: color;

        &:hover,
        &.color-main {
            color: $color-main;
        }
    }

    h2 {
        font-weight: 800;
        margin-left: 11px;
    }

    .mat-raised-button {
        background: $color-white;

        .mat-button-wrapper {
            color: $color-main;
        }
    }

    &.highlight {
        background: $color-main;
        box-shadow: none;
        color: $color-white;
        position: static;

        &.small {
            height: 30px;
            min-height: 30px;

            .mat-toolbar-row {
                height: 30px;
                font-size: .7em;
            }
        }
    }
}

.mat-button-wrapper {
    color: $color-main;
}

.mat-raised-button {
    background: $color-main;

    .mat-button-wrapper {
        color: $color-white;
    }

    &.bw {
        background: $color-white;

        .mat-button-wrapper {
            color: $color-grey-dark;
        }
    }
}

.mat-progress-spinner path,
.mat-spinner path {
    stroke: $color-main;
}

.mat-sidenav-container {
    height: 100vh;
}

.mat-sidenav-content {
    box-sizing: border-box;
    padding-top: 0px;
}

admin .mat-sidenav-content {
    padding-top: 0;
}

.warn-snackbar {
    background: darken($color-red, 15%) !important;
}

.container {
    box-sizing: border-box;
    padding: 30px;
}

.mat-card {
    margin-bottom: 5px;
}

.block {
    display: block;
}

.block-center {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.fill-space {
    flex: 1 1 auto;
}

.center {
    text-align: center;
}

.error {
    color: $color-red;
}

.hint {
    color: $color-grey;
    font-size: .7em;
    margin-top: 10px;
}

.color-main {
    color: $color-main;
}

/*dnd*/
.dnd-drag-start {
    -moz-transform:scale(0.8);
    -webkit-transform:scale(0.8);
    transform:scale(0.8);
    opacity:0.7;
    border: 2px dashed #000;
}

.dnd-drag-enter {
    opacity:0.7;
    border: 2px dashed #000;
}

.dnd-drag-over {
    border: 2px dashed #000;
}

.dnd-sortable-drag {
  -moz-transform:scale(0.9);
  -webkit-transform:scale(0.9);
  transform:scale(0.9);
  opacity:0.7;
  border: 1px dashed #000;
}